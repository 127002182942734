
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const constantRoutes = [
    {
        path: '/',
        component: () => import('@/pages/index/index'),
    }
]

const router = new VueRouter({
    mode: 'history',
    scrollBehavior: () => ({
        x: 0,
        y: 0
    }),
    routes: constantRoutes
})

export default router
